<template>

  <div class="enroll-page">

     
        <div class="van-cell">
          <span class="title2">{{activity.activity_name}}</span>
        </div>
        
        <div class="van-cell flex flex-alicen">
          <van-icon name="clock-o" class="mr5" size="18"></van-icon>
          <span class="font-14 text-grey">{{activity.start_time}} 至 {{activity.end_time}}</span>
        </div>
    


 
         <van-field
        v-model="FormData.linkname"
        autosize
        left-icon="user-o"
        label="姓名"
        type="text"
        required
      /> 
           <van-field
        v-model="FormData.phone"
        autosize
        left-icon="phone-o"
        label="电话"
        type="num"
        required
      />    
         <van-field
        v-model="FormData.email"
        autosize
        left-icon="envelop-o"
        label="邮箱"
        type="text"
      />    
          <!-- <van-field
        v-model="FormData.company"
        autosize
        left-icon="shop-o"
        label="公司"
        type="text"
      /> -->
           <van-field
        v-model="FormData.address"
        autosize
        left-icon="shop-o"
        label="地址"
        type="text"
      />

  <van-field
    v-model="FormData.note"
    rows="3"
    autosize
    left-icon="orders-o"
    label="备注"
    type="textarea"
    maxlength="50"
    placeholder="请输入备注"
  />

 

 <van-radio-group v-model="FormData.card_id" class="card-section">

    <van-cell :title="item.card_name" :label="'剩余'+item.rest_num+'张'" clickable
     @click="FormData.card_id = item.id" v-for="(item,index) in activity.card_list" :key="index" :class="FormData.card_id == item.id?'card-wrap card-wrap-hover':'card-wrap'">
       <div slot='default' class="text-theme3 mr5" >
           {{item.amount==0?'免费':'￥'+item.amount}}
       </div>
       <van-radio slot="right-icon" :name="item.id" />
    </van-cell>

</van-radio-group>


  <van-radio-group v-model="pay_type">     
     <van-cell-group class="from-group-item">
      <van-cell title="在线支付" clickable @click="pay_type='1'">
         <van-radio slot="right-icon" name="1" />
      </van-cell>
    </van-cell-group>
  </van-radio-group>

     
   <div class="btn-wrap">
           <van-button type="info" class=""   @click="toCommit" block>报名提交</van-button>
  </div>
          
    

    <!-- 加载框 -->
    <!-- <myloading tips="加载中..." layer show></myloading> -->
  </div>
</template>
<script>
import {
  Field,
  Row,
  Icon,
  Button,
  RadioGroup, 
  Radio,
  Col,
  Cell,
  CellGroup
} from "vant";


import { tichets_add,activity_detail} from "request/api";

export default {
  components: {
    [Field.name]: Field,
    [Row.name]: Row,
    [Icon.name]:Icon,
    [Button.name]:Button,
    [Radio.name]:Radio,
    [RadioGroup.name]:RadioGroup,
    [Col.name]: Col,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      activity:{},
      pay_type:'1',
      FormData:{
        linkname:'',
        phone:'',
        email:'',
        address:'',
        note:'',
        order_type:0, // 订单类型，0是线上订单，1是线下订单，2是手动售票的订单
        card_id:0
      },
	  
	  back: 0  ,//这个用来控制是否又返回页
	  goods: '', //返回页的商品信息

    
    };
  },
 
  mounted: function() {
    let id = this.$route.query.id;
	if (this.$route.query.back) {
		this.back = this.$route.query.back
		this.goods = this.$route.query.goods
	}
    this.loadData(id);
  },
  methods: {
    loadData(id){
	 activity_detail({activity_id:id}).then(res => {
		 if (res.code == 1) {
			 this.activity = res.data
			 this.FormData.card_id = this.activity.card_list[0].id
		 } else {
			 this.$dialog({
			            message: res.msg
			        });
		 }
	 }).catch(err => {
		 this.$dialog({
		        message: "活动数据获取失败"
		      });
	 })
      
    },
    toCommit:function(){
      this.showLoading('正在提交',true);
      let r = this.check();

      if(!r){
        this.hideLoading();
        return false;
      }


      let params = {
        activity_id:this.activity.id,
        user_id:this.$store.state.userInfo.id,
        card_id:this.FormData.card_id,
        linkman:this.FormData.linkname,
        phone:this.FormData.phone,
        note:this.FormData.note,
        order_type:this.FormData.order_type
      }

      //处理部分表单字段 为json数据
      let my_field = {
        email:this.FormData.email,
        address:this.FormData.address
      }
      this.$set(params,'enroll_json',JSON.stringify(my_field));


        tichets_add(params).then(res =>{
         this.hideLoading();
         if(res.code == 1){
             if(res.data.is_free==1){
				 this.$router.replace({ path: "/activity/enroll/moremessage" ,query:{id: res.data.id }});
             }else{
                 //跳转至支付页面
				 
                 this.$router.replace({path:'/payment/method',query:{id:res.data.id,type: 'ticket'}});
             }
         }else{
           this.$dialog({
             message:res.msg
           })
         }
       

      }).catch (res =>{
         this.hideLoading();
         this.$dialog({message:res});
      });
    },
    check(){
      if(this.FormData.linkname ==''){
         this.$notify({type:'danger',message:'姓名不能为空！'})
         return false;
      }
      if(this.FormData.phone == ''){
         this.$notify({type:'danger',message:'号码不能为空！'})
         return false;
      }

      if(this.FormData.card_id == 0 ){
        this.$notify({type:'danger',message:'请选择卡券'})
        return false;
      }
      return true;
    }
  }
};
</script>
<style lang="less">
.enroll-page{
.from {
  &-group-item {
    margin-top: 5px;
  }
  &-bottom-button {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &-agree {
    font-size: 14px;
    padding: 10px 15px;
  }
}
.btn-wrap{
  margin: 30px;
}
.card-section{
  margin: 20px 5px;
  .card-wrap{
    margin: 10px 0 ;
  .text-theme3{
        line-height: 45px;
        height: 45px;
  }
}
.card-wrap-hover{
      box-shadow: 3px 3px 4px rgba(0, 102, 204, 0.2);
   
}
}
.van-field__label{
  width: 1.4rem;
}
}

</style>